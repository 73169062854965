// import React, { useState } from "react";
// import ScrollToTop from "../../Components/ScrollToTop/ScrollToTop.jsx";
// import styled from "styled-components";

// const StyledH1 = styled.h1`
//   text-align: center;
//   font-size: 2rem;
//   margin-bottom: 2rem;
//   text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.3);
// `;

// const FAQContainer = styled.div`
//   max-width: 800px;
//   margin: 2rem auto;
//   padding: 0 1rem;
//   font-family: Arial, sans-serif;
// `;

// const FAQItem = styled.div`
//   margin-bottom: 1rem;
//   border-bottom: 1px solid #ccc;
// `;

// const Question = styled.h2`
//   font-size: 1.2rem;
//   font-weight: bold;
//   cursor: pointer;
//   position: relative;
//   padding-right: 1.5rem;
//   text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.3);
//   opacity: ${(props) => (props.isOpen ? "1" : "0.5")};

//   &::after {
//     content: "${(props) => (props.isOpen ? "▲" : "▼")}";
//     position: absolute;
//     right: 0;
//     top: 0;
//     font-size: 1rem;
//   }
// `;

// const Answer = styled.p`
//   line-height: 1.6;
//   margin: ${(props) => (props.isOpen ? "0.5rem 0 1rem 0" : "0")};
//   max-height: ${(props) => (props.isOpen ? "1000px" : "0")};
//   overflow: hidden;
//   transition: max-height 0.3s ease;
//   text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);
// `;

// function FAQ() {
//   ScrollToTop();

//   const [openIndexes, setOpenIndexes] = useState([]);

//   const toggleAnswer = (index) => {
//     setOpenIndexes((prevIndexes) =>
//       prevIndexes.includes(index)
//         ? prevIndexes.filter((i) => i !== index)
//         : [...prevIndexes, index]
//     );
//   };

//   const faqs = [
//     {
//       question: "What is MaxBogey?",
//       answer:
//         "MaxBogey is an all-in-one golf app designed to provide golfers with every feature they need in a single, affordable platform. From tracking your scores to connecting with friends, MaxBogey has it all.",
//     },
//     {
//       question: "How much does MaxBogey cost?",
//       answer:
//         "MaxBogey is available for just $2.99 per month or $29.99 per year. By choosing the annual subscription, you save over 15% compared to the monthly plan!",
//     },
//     {
//       question: "How can MaxBogey be so cheap?",
//       answer:
//         "MaxBogey is developed and maintained by a single golf enthusiast who is passionate about the sport. By keeping the team small and focusing on delivering value, we're able to offer the app at a price that's accessible to everyone.",
//     },
//     {
//       question: "What features does MaxBogey offer?",
//       answer:
//         "MaxBogey includes a wide range of features such as score tracking, shot statistics, GPS mapping of courses, club yardages, practice logs, goal setting, social networking with other golfers, and much more. Visit our Home page for a detailed list.",
//     },
//     {
//       question: "Is there a free trial available?",
//       answer:
//         "Yes! We offer a 7-day free trial so you can explore all the features MaxBogey has to offer before subscribing.",
//     },
//     {
//       question: "Do I need to create an account to use MaxBogey?",
//       answer:
//         "While you can use some basic features without an account, creating an account allows you to access all features, save your data, and connect with other golfers.",
//     },
//     {
//       question: "How do I subscribe to MaxBogey?",
//       answer: "You can subscribe within the App Store or Google Play Store.",
//     },
//     {
//       question: "Can I cancel my subscription at any time?",
//       answer:
//         "Absolutely! You can cancel your subscription at any time through your account settings. Your subscription will remain active until the end of the current billing period.",
//     },
//     {
//       question: "Is my personal data secure with MaxBogey?",
//       answer:
//         "Yes, we take your privacy and data security very seriously. We employ industry-standard encryption and security practices to protect your personal information. For more details, please review our Privacy Policy.",
//     },
//     {
//       question: "Which devices is MaxBogey compatible with?",
//       answer:
//         "MaxBogey is available for both iOS and Android devices. You can download the app from the App Store or Google Play Store.",
//     },
//     {
//       question: "How can I contact support if I have issues?",
//       answer: (
//         <>
//           If you have any questions or need assistance, feel free to reach out
//           to us at{" "}
//           <a style={{ color: "blue" }} href="mailto:contact@maxbogey.com">
//             contact@maxbogey.com
//           </a>
//           . We're here to help!
//         </>
//       ),
//     },
//     {
//       question: "Does MaxBogey include real-time GPS mapping?",
//       answer:
//         "Yes, MaxBogey offers real-time GPS mapping of golf courses, allowing you to see distances to hazards, greens, and more. This feature helps you make informed decisions on the course.",
//     },
//     {
//       question: "Can I connect with friends on MaxBogey?",
//       answer:
//         "Definitely! MaxBogey includes social features that allow you to add friends, share your scores, and message them directly.",
//     },
//     {
//       question: "Will there be updates and new features added?",
//       answer:
//         "Yes, we're continually working to improve MaxBogey. As a user, you'll receive updates with new features and enhancements regularly. Please contact us if you have any feature requests or suggestions!",
//     },
//   ];

//   return (
//     <FAQContainer>
//       <StyledH1>Frequently Asked Questions</StyledH1>
//       {faqs.map((faq, index) => (
//         <FAQItem key={index}>
//           <Question
//             onClick={() => toggleAnswer(index)}
//             isOpen={openIndexes.includes(index)}
//           >
//             {faq.question}
//           </Question>
//           <Answer isOpen={openIndexes.includes(index)}>{faq.answer}</Answer>
//         </FAQItem>
//       ))}
//     </FAQContainer>
//   );
// }

// export default FAQ;

import React, { useState, useRef, useEffect } from "react";
import ScrollToTop from "../../Components/ScrollToTop/ScrollToTop.jsx";
import styled from "styled-components";

const StyledH1 = styled.h1`
  text-align: center;
  font-size: 1.6rem;
  margin-bottom: 3rem;
  text-shadow: 0px 0.75px 1px rgba(0, 0, 0, 0.175);
`;

const FAQContainer = styled.div`
  max-width: 800px;
  margin: 0 auto;
  font-family: Arial, sans-serif;
`;

const FAQItem = styled.div`
  margin-bottom: 1rem;
  border-bottom: 1px solid #ccc;
`;

const Question = styled.h2`
  font-size: 1.2rem;
  font-weight: bold;
  cursor: pointer;
  position: relative;
  padding-right: 1.5rem;
  text-shadow: 0px 0.75px 1px rgba(0, 0, 0, 0.175);
  opacity: ${(props) => (props.isOpen ? "1" : "0.7")};

  &::after {
    content: "${(props) => (props.isOpen ? "▲" : "▼")}";
    position: absolute;
    right: 0;
    top: 0;
    font-size: 1rem;
  }
`;

const AnswerWrapper = styled.div`
  overflow: hidden;
  transition: height 0.3s ease;
  height: ${(props) => (props.isOpen ? `${props.contentHeight}px` : "0px")};
`;

const Answer = styled.div`
  line-height: 1.6;
  padding: 0.5rem 0;
  text-shadow: 0px 0.75px 1px rgba(0, 0, 0, 0.1);
`;

function FAQ() {
  ScrollToTop();

  const [openIndexes, setOpenIndexes] = useState([]);

  const toggleAnswer = (index) => {
    setOpenIndexes((prevIndexes) =>
      prevIndexes.includes(index)
        ? prevIndexes.filter((i) => i !== index)
        : [...prevIndexes, index]
    );
  };

  const faqs = [
    {
      question: "What is MaxBogey?",
      answer:
        "MaxBogey is an all-in-one golf app designed to provide golfers with every feature they need in a single, affordable platform. From tracking your scores to connecting with friends, MaxBogey has it all.",
    },
    {
      question: "How much does MaxBogey cost?",
      answer:
        "MaxBogey is available for just $4.99 per month or $29.99 per year. By choosing the annual subscription, you save 49% compared to the monthly plan!",
    },
    {
      question: "How can MaxBogey be so cheap?",
      answer:
        "MaxBogey is developed and maintained by a single golf enthusiast who is passionate about the sport. By keeping the team small and focusing on delivering value, we're able to offer the app at a price that's accessible to everyone.",
    },
    {
      question: "What features does MaxBogey offer?",
      answer:
        "MaxBogey includes a wide range of features such as score tracking, shot statistics, GPS mapping of courses, club yardages, practice logs, goal setting, social networking with other golfers, and much more. Visit our Home page for a detailed list.",
    },
    {
      question: "Is there a free trial available?",
      answer:
        "Yes! We offer a 7-day free trial so you can explore all the features MaxBogey has to offer before subscribing.",
    },
    {
      question: "Do I need to create an account to use MaxBogey?",
      answer:
        "While you can use some basic features without an account, creating an account allows you to access all features, save your data, and connect with other golfers.",
    },
    {
      question: "How do I subscribe to MaxBogey?",
      answer:
        "You can subscribe by downloading the app from either Google Play Store or the App Store.",
    },
    {
      question: "Can I cancel my subscription at any time?",
      answer:
        "Absolutely! You can cancel your subscription at any time through your account settings. Your subscription will remain active until the end of the current billing period.",
    },
    {
      question: "Is my personal data secure with MaxBogey?",
      answer:
        "Yes, we take your privacy and data security very seriously. We employ industry-standard encryption and security practices to protect your personal information. For more details, please review our Privacy Policy.",
    },
    {
      question: "Which devices is MaxBogey compatible with?",
      answer:
        "MaxBogey is available for both iOS and Android devices. You can download the app from the App Store or Google Play Store.",
    },
    {
      question: "How can I contact support if I have issues?",
      answer: (
        <>
          If you have any questions or need assistance, feel free to reach out
          to us at{" "}
          <a style={{ color: "blue" }} href="mailto:contact@maxbogey.com">
            contact@maxbogey.com
          </a>
          . We're here to help!
        </>
      ),
    },
    {
      question: "Does MaxBogey include real-time GPS mapping?",
      answer:
        "Yes, MaxBogey offers real-time GPS mapping of golf courses, allowing you to see distances to hazards, greens, and more. This feature helps you make informed decisions on the course.",
    },
    {
      question: "Can I connect with friends on MaxBogey?",
      answer:
        "Definitely! MaxBogey includes social features that allow you to add friends, share your scores, and message them directly.",
    },
    {
      question: "Will there be updates and new features added?",
      answer:
        "Yes, we're continually working to improve MaxBogey. As a user, you'll receive updates with new features and enhancements regularly. Please contact us if you have any feature requests or suggestions!",
    },
  ];

  return (
    <FAQContainer>
      <StyledH1>Frequently Asked Questions</StyledH1>
      {faqs.map((faq, index) => (
        <FAQItem key={index}>
          <Question
            onClick={() => toggleAnswer(index)}
            isOpen={openIndexes.includes(index)}
          >
            {faq.question}
          </Question>
          <FAQAnswer isOpen={openIndexes.includes(index)} answer={faq.answer} />
        </FAQItem>
      ))}
    </FAQContainer>
  );
}

function FAQAnswer({ isOpen, answer }) {
  const [height, setHeight] = useState(0);
  const contentRef = useRef(null);

  useEffect(() => {
    if (isOpen && contentRef.current) {
      setHeight(contentRef.current.scrollHeight);
    } else {
      setHeight(0);
    }
  }, [isOpen]);

  return (
    <AnswerWrapper isOpen={isOpen} contentHeight={height}>
      <Answer ref={contentRef}>{answer}</Answer>
    </AnswerWrapper>
  );
}

export default FAQ;
