import "./Loader.scss";

function Loader() {
  return (
    <div className="spinner" id="loader">
      <div className="spinner1"></div>
    </div>
  );
}

export default Loader;
