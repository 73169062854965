// import React from "react";
// import ScrollToTop from "../../Components/ScrollToTop/ScrollToTop.jsx";
// import styled from "styled-components";

// const StyledH1 = styled.h1`
//   text-align: center;
//   font-size: 1.7rem;
//   text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.3);
//   margin-bottom: 2rem;
// `;
// const AboutContainer = styled.div`
//   max-width: 800px;
//   margin: 0 auto;
//   font-family: Arial, sans-serif;
// `;

// const Paragraph = styled.p`
//   line-height: 1.6;
//   text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);

//   margin: 1rem 0;
// `;

// function AboutUs() {
//   ScrollToTop();
//   return (
//     <AboutContainer>
//       <StyledH1>Welcome to MaxBogey!</StyledH1>
//       <Paragraph>
// As a 30-year-old golf enthusiast, I found myself frustrated by the lack
// of comprehensive and affordable golf apps. Last year, in my quest to
// improve my game, I searched for an app that would provide everything I
// wanted—a single platform that combined all the features a golfer needs
// at a reasonable price. Unfortunately, all the available options were
// either too expensive or required juggling multiple apps to get the full
// experience.
//       </Paragraph>
//       <Paragraph>
//         Determined to find a better solution, I decided to leverage my
//         programming skills and passion for golf. While pursuing my studies in
//         programming, I set out to create MaxBogey—a golf app designed by
//         golfers, for golfers. My goal was simple: to develop an all-in-one app
//         that offers every feature a golfer could want, all in one place and at a
//         reasonable cost.
//       </Paragraph>
//       <Paragraph>
// MaxBogey is the culmination of that vision. Whether you're tracking your
// stats, connecting with friends, or seeking to improve your skills,
// MaxBogey brings it all together in one convenient platform.
//       </Paragraph>
//       <Paragraph>
//         Join me on this journey to make golf more enjoyable and accessible for
//         everyone. Let's take your game to the next level together!
//       </Paragraph>
//     </AboutContainer>
//   );
// }

// export default AboutUs;

// import React from "react";
// import styled from "styled-components";
// import ScrollToTop from "../../Components/ScrollToTop/ScrollToTop.jsx";
// import ScorecardWMap from "../../assets/images/Scorecard-w-map.jpg";
// import PutterStats from "../../assets/images/Putterstats.jpg";
// import Message from "../../assets/images/Message.jpg";
// import Courses from "../../assets/images/Courses.jpg";

// const StyledContainer = styled.div`
//   max-width: 800px;
//   margin: 0 auto;
// `;

// const FeatureSection = styled.div`
//   display: flex;
//   align-items: center;
//   flex-direction: ${(props) => (props.reverse ? "row-reverse" : "row")};
//   margin: 2rem 0;
//   text-align: center;
//   text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.3);

//   @media (max-width: 699px) {
//     flex-direction: column;
//   }
// `;

// const FeatureImage = styled.img`
//   flex: 1;
//   max-width: 300px;
//   border-radius: 15px;
//   box-shadow: 0 2px 40px 0px rgba(0, 0, 0, 0.3);

//   @media (max-width: 699px) {
//     max-width: 90%;
//   }
// `;

// const FeatureContent = styled.div`
//   flex: 1;
//   padding: 1rem 3rem;
// `;

// function Home() {
//   ScrollToTop();

//   const features = [
//     {
//       image: Courses,
//       title: "I am MaxBogey!",
//       description:
//         "As a 30-year-old golf enthusiast, I found myself frustrated by the lack of comprehensive and affordable golf apps. Last year, in my quest to improve my game, I searched for an app that would provide everything I wanted—a single platform that combined all the features a golfer needs at a reasonable price. Unfortunately, all the available options were either too expensive or required juggling multiple apps to get the full experience.",
//     },
//     {
//       image: ScorecardWMap,
//       title: "Building a Better Golf App",
//       description:
//         "Determined to find a better solution, I decided to leverage my programming skills and passion for golf. While pursuing my studies in programming, I set out to create MaxBogey—a golf app designed by golfers, for golfers. My goal was simple: to develop an all-in-one app that offers every feature a golfer could want, all in one place and at a reasonable cost.",
//     },
//     {
//       image: PutterStats,
//       title: "MaxBogey: The Ultimate Golf App",
//       description:
//         "MaxBogey is the culmination of that vision. Whether you're tracking your stats, connecting with friends, or seeking to improve your skills, MaxBogey brings it all together in one convenient platform.",
//     },

//     {
//       image: Message,
//       title: "Let's Improve our Game Together",
//       description:
//         "Join me on this journey to make golf more enjoyable and accessible for everyone. Let's take your game to the next level together!",
//     },
//   ];

//   return (
//     <StyledContainer>
//       {features.map((feature, index) => (
//         <FeatureSection key={index} reverse={index % 2 !== 0}>
//           <FeatureImage src={feature.image} alt={feature.title} />
//           <FeatureContent>
//             <h2>{feature.title}</h2>
//             <p>{feature.description}</p>
//           </FeatureContent>
//         </FeatureSection>
//       ))}
//     </StyledContainer>
//   );
// }

// export default Home;

import React from "react";
import styled from "styled-components";
import ScrollToTop from "../../Components/ScrollToTop/ScrollToTop.jsx";
import ScorecardWMap from "../../assets/images/Scorecard-w-map.jpg";
import PutterStats from "../../assets/images/Putterstats.jpg";
import Message from "../../assets/images/Message.jpg";
import Courses from "../../assets/images/Courses.jpg";

const StyledContainer = styled.div`
  max-width: 800px;
  margin: 0 auto;
`;

const FeatureSection = styled.div`
  display: flex;
  align-items: center;
  flex-direction: ${(props) => (props.reverse ? "row-reverse" : "row")};
  margin: 2rem 0;
  text-align: center;
  text-shadow: 0px 0.75px 1px rgba(0, 0, 0, 0.175);

  @media (max-width: 699px) {
    flex-direction: column;
  }
`;

const FeatureImage = styled.img`
  flex: 1;
  max-width: 300px;
  border-radius: 15px;
  box-shadow: 0 2px 40px 0px rgba(0, 0, 0, 0.3);
  object-fit: contain;
  height: auto;

  @media (max-width: 699px) {
    max-width: 90%;
  }
`;

const FeatureContent = styled.div`
  flex: 1;
  padding: 1rem 3rem;
`;

function Home() {
  ScrollToTop();

  const features = [
    {
      image: Courses,
      title: "I am MaxBogey!",
      description: `As a 30-year-old golf enthusiast from Norway, I found myself frustrated by the lack of comprehensive and affordable golf apps. Last year, in my quest to improve my game, I searched for an app that would provide everything I wanted—a single platform that combined all the features a golfer needs at a reasonable price. Unfortunately, all the available options were either too expensive or required juggling multiple apps to get the full experience.`,
    },
    {
      image: ScorecardWMap,
      title: "Building a Better Golf App",
      description:
        "Determined to find a better solution, I decided to leverage my programming skills and passion for golf. While pursuing my studies in programming, I set out to create MaxBogey—a golf app designed by golfers, for golfers. My goal was simple: to develop an all-in-one app that offers every feature a golfer could want, all in one place and at a reasonable cost.",
    },
    {
      image: PutterStats,
      title: "MaxBogey: The Ultimate Golf App",
      description:
        "MaxBogey is the culmination of that vision. Whether you're tracking your stats, connecting with friends, or seeking to improve your skills, MaxBogey brings it all together in one convenient platform.",
    },
    {
      image: Message,
      title: "Let's Improve our Game Together",
      description:
        "Join me on this journey to make golf more enjoyable and accessible for everyone. Let's take your game to the next level together!",
    },
  ];

  return (
    <StyledContainer>
      {features.map((feature, index) => (
        <FeatureSection key={index} reverse={index % 2 !== 0}>
          <FeatureImage src={feature.image} alt={feature.title} />
          <FeatureContent>
            <h2>{feature.title}</h2>
            <p>{feature.description}</p>
          </FeatureContent>
        </FeatureSection>
      ))}
    </StyledContainer>
  );
}

export default Home;
