// import React from "react";
// import ScrollToTop from "../../Components/ScrollToTop/ScrollToTop.jsx";
// import styled from "styled-components";

// const StyledH1 = styled.h1`
//   text-align: center;
//   font-size: 1.7rem;
//   text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.3);
// `;

// const TermsContainer = styled.div`
//   max-width: 800px;
//   margin: 0 auto;
//   font-family: Arial, sans-serif;
// `;

// const SectionTitle = styled.h2`
//   font-weight: bold;
//   margin-top: 2rem;
//   text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.3);
// `;

// const Paragraph = styled.p`
//   line-height: 1.6;
//   margin: 1rem 0;
//   text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);
// `;

// const BoldText = styled.span`
//   font-weight: bold;
// `;

// function Terms() {
//   ScrollToTop();

//   return (
//     <TermsContainer>
//       <StyledH1>Terms & Conditions</StyledH1>

//       <SectionTitle>1. Introduction</SectionTitle>
//       <Paragraph>
//         Welcome to MaxBogey. By using the MaxBogey app, you agree to these Terms
//         of Service and the Privacy Policy. These Terms cover your rights, our
//         responsibilities, and legal protections for both parties, ensuring that
//         MaxBogey remains GDPR compliant.
//       </Paragraph>

//       <SectionTitle>2. Data Controller and Contact Information</SectionTitle>
//       <Paragraph>
//         MaxBogey, <BoldText>Korny Applications</BoldText>, is the data
//         controller responsible for processing your personal data. For any
//         questions regarding your data, please contact us at{" "}
//         <a href="mailto:contact@maxbogey.com">contact@maxbogey.com</a>.
//       </Paragraph>

//       <SectionTitle>3. Data Collection</SectionTitle>
//       <Paragraph>
//         MaxBogey does not require users to create an account to use the app.
//         However, if you choose to create an account, we may collect the
//         following personal information:
//       </Paragraph>
//       <ul>
//         <li>Required Account Data: Username, email address, and password.</li>
//         <li>
//           Optional Data: Posts, images, shot statistics, notes, logs, goals,
//           golf club details, and yardages.
//         </li>
//       </ul>
//       <Paragraph>
//         Users who do not create an account will not have any personal data
//         collected or stored.
//       </Paragraph>

//       <SectionTitle>4. Data Usage</SectionTitle>
//       <Paragraph>We only use your data for the following purposes:</Paragraph>
//       <ul>
//         <li>To operate the MaxBogey app.</li>
//         <li>To allow you to post content, messages, and statistics.</li>
//         <li>To facilitate account creation and login.</li>
//       </ul>
//       <Paragraph>
//         Data you choose to share, such as posts, images, and private messages,
//         will be securely stored. Shot statistics, logs, notes, and club data
//         will be stored only if you opt to use those features.
//       </Paragraph>

//       <SectionTitle>5. Legal Basis for Data Processing</SectionTitle>
//       <Paragraph>
//         The legal basis for collecting and processing your personal data is your
//         explicit consent, which is given when you create an account, post
//         content, or provide additional data. Users who choose not to create an
//         account will not have any personal data processed.
//       </Paragraph>

//       <SectionTitle>6. User Rights</SectionTitle>
//       <Paragraph>
//         As a user, you have the following rights under GDPR:
//       </Paragraph>
//       <ul>
//         <li>
//           <BoldText>Right of Access:</BoldText> You can request access to any
//           data we store about you.
//         </li>
//         <li>
//           <BoldText>Right to Rectification:</BoldText> You can request
//           corrections to inaccurate data.
//         </li>
//         <li>
//           <BoldText>Right to Erasure ("Right to be Forgotten"):</BoldText> You
//           can request the deletion of your personal data at any time.
//         </li>
//         <li>
//           <BoldText>Right to Restrict Processing:</BoldText> You can request
//           that we restrict the processing of your data.
//         </li>
//         <li>
//           <BoldText>Right to Data Portability:</BoldText> You can request your
//           data in a structured, commonly used format.
//         </li>
//         <li>
//           <BoldText>Right to Withdraw Consent:</BoldText> You can withdraw your
//           consent for data processing at any time by deleting your account or
//           contacting us at{" "}
//           <a href="mailto:contact@maxbogey.com">contact@maxbogey.com</a>.
//         </li>
//       </ul>

//       <SectionTitle>7. Data Retention</SectionTitle>
//       <Paragraph>
//         We will retain your personal data only for as long as it is necessary to
//         provide the services or fulfill legal obligations. If you choose to
//         delete your account, your personal data will be deleted immediately.
//       </Paragraph>

//       <SectionTitle>8. Data Security</SectionTitle>
//       <Paragraph>
//         We take appropriate technical and organizational measures to ensure the
//         security of your personal data. This includes encryption of private
//         messages and the use of secure servers. However, no system is completely
//         secure, and we cannot guarantee the absolute security of your data.
//       </Paragraph>

//       <SectionTitle>9. Third-Party Services</SectionTitle>
//       <Paragraph>
//         We do not share your personal data with third parties without your
//         consent, except where required by law. Any data you provide is stored
//         securely on our servers or trusted third-party service providers who
//         adhere to GDPR standards.
//       </Paragraph>

//       <SectionTitle>10. Changes to the Terms</SectionTitle>
//       <Paragraph>
//         MaxBogey reserves the right to modify these Terms at any time. We will
//         notify you of any significant changes via email or in-app notifications.
//         Continued use of the app after the changes take effect signifies your
//         agreement to the revised terms.
//       </Paragraph>

//       <SectionTitle>11. Governing Law</SectionTitle>
//       <Paragraph>
//         These Terms are governed by the laws of <BoldText>Norway</BoldText>. By
//         using the MaxBogey app, you consent to the jurisdiction of these laws
//         for any disputes.
//       </Paragraph>

//       <SectionTitle>12. Acceptance of Terms</SectionTitle>
//       <Paragraph>
//         By creating an account or using the MaxBogey app, you agree to these
//         Terms of Service and acknowledge that you understand your rights under
//         GDPR.
//       </Paragraph>
//     </TermsContainer>
//   );
// }

// export default Terms;

import React, { useState, useRef, useEffect } from "react";
import ScrollToTop from "../../Components/ScrollToTop/ScrollToTop.jsx";
import styled from "styled-components";

const StyledH1 = styled.h1`
  text-align: center;
  font-size: 1.6rem;
  text-shadow: 0px 0.75px 1px rgba(0, 0, 0, 0.175);
  margin-bottom: 3rem;
`;

const TermsContainer = styled.div`
  max-width: 800px;
  margin: 0 auto;
  font-family: Arial, sans-serif;
`;

const SectionTitle = styled.h2`
  font-weight: bold;
  cursor: pointer;
  position: relative;
  text-shadow: 0px 0.75px 1px rgba(0, 0, 0, 0.1);
  margin: 1.2rem 0;
  opacity: 0.8;
  font-size: 1.2rem;

  &::after {
    content: "${(props) => (props.isOpen ? "▲" : "▼")}";
    position: absolute;
    right: 0;
    top: 0;
    font-size: 1rem;
  }
`;

const ParagraphWrapper = styled.div`
  overflow: hidden;
  transition: height 0.3s ease;
  height: ${(props) => (props.isOpen ? `${props.contentHeight}px` : "0px")};
`;

const Paragraph = styled.div`
  line-height: 1.6;
  padding: 0.2rem 0;
  margin: 0;
  text-shadow: 0px 0.75px 1px rgba(0, 0, 0, 0.1);
`;

const BoldText = styled.span`
  font-weight: bold;
`;

function Terms() {
  ScrollToTop();

  const [openIndexes, setOpenIndexes] = useState([]);

  const toggleSection = (index) => {
    setOpenIndexes((prevIndexes) =>
      prevIndexes.includes(index)
        ? prevIndexes.filter((i) => i !== index)
        : [...prevIndexes, index]
    );
  };

  const sections = [
    {
      title: "1. Introduction",
      content: (
        <Paragraph>
          Welcome to MaxBogey. By using the MaxBogey app, you agree to these
          Terms of Service and the Privacy Policy. These Terms cover your
          rights, our responsibilities, and legal protections for both parties,
          ensuring that MaxBogey remains GDPR compliant.
        </Paragraph>
      ),
    },
    {
      title: "2. Data Controller and Contact Information",
      content: (
        <Paragraph>
          MaxBogey, <BoldText>Korny Applications</BoldText>, is the data
          controller responsible for processing your personal data. For any
          questions regarding your data, please contact us at{" "}
          <a
            style={{ color: "black", textDecoration: "underline" }}
            href="mailto:contact@maxbogey.com"
          >
            contact@maxbogey.com
          </a>
          .
        </Paragraph>
      ),
    },
    {
      title: "3. Data Collection",
      content: (
        <Paragraph>
          MaxBogey does not require users to create an account to use the app.
          However, if you choose to create an account, we may collect the
          following personal information:
          <ul>
            <li>
              Required Account Data: Username, email address, and password.
            </li>
            <li>
              Optional Data: Posts, images, shot statistics, notes, logs, goals,
              golf club details, and yardages.
            </li>
          </ul>
          Users who do not create an account will not have any personal data
          collected or stored.
        </Paragraph>
      ),
    },
    {
      title: "4. Data Usage",
      content: (
        <Paragraph>
          We only use your data for the following purposes:
          <ul>
            <li>To operate the MaxBogey app.</li>
            <li>To allow you to post content, messages, and statistics.</li>
            <li>To facilitate account creation and login.</li>
          </ul>
          Data you choose to share, such as posts, images, and private messages,
          will be securely stored. Shot statistics, logs, notes, and club data
          will be stored only if you opt to use those features.
        </Paragraph>
      ),
    },
    {
      title: "5. Legal Basis for Data Processing",
      content: (
        <Paragraph>
          The legal basis for collecting and processing your personal data is
          your explicit consent, which is given when you create an account, post
          content, or provide additional data. Users who choose not to create an
          account will not have any personal data processed.
        </Paragraph>
      ),
    },
    {
      title: "6. User Rights",
      content: (
        <Paragraph>
          As a user, you have the following rights under GDPR:
          <ul style={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
            <li>
              <BoldText>Right of Access:</BoldText> You can request access to
              any data we store about you.
            </li>
            <li>
              <BoldText>Right to Rectification:</BoldText> You can request
              corrections to inaccurate data.
            </li>
            <li>
              <BoldText>Right to Erasure ("Right to be Forgotten"):</BoldText>{" "}
              You can request the deletion of your personal data at any time.
            </li>
            <li>
              <BoldText>Right to Restrict Processing:</BoldText> You can request
              that we restrict the processing of your data.
            </li>
            <li>
              <BoldText>Right to Data Portability:</BoldText> You can request
              your data in a structured, commonly used format.
            </li>
            <li>
              <BoldText>Right to Withdraw Consent:</BoldText> You can withdraw
              your consent for data processing at any time by deleting your
              account or contacting us at{" "}
              <a
                style={{ color: "black", textDecoration: "underline" }}
                href="mailto:contact@maxbogey.com"
              >
                contact@maxbogey.com
              </a>
              .
            </li>
          </ul>
        </Paragraph>
      ),
    },
    // Additional sections follow the same pattern
  ];

  return (
    <TermsContainer>
      <StyledH1>Terms & Conditions</StyledH1>
      {sections.map((section, index) => (
        <div key={index}>
          <SectionTitle
            onClick={() => toggleSection(index)}
            isOpen={openIndexes.includes(index)}
          >
            {section.title}
          </SectionTitle>
          <TermsContent isOpen={openIndexes.includes(index)}>
            {section.content}
          </TermsContent>
        </div>
      ))}
    </TermsContainer>
  );
}

function TermsContent({ isOpen, children }) {
  const [height, setHeight] = useState(0);
  const contentRef = useRef(null);

  useEffect(() => {
    if (isOpen && contentRef.current) {
      setHeight(contentRef.current.scrollHeight);
    } else {
      setHeight(0);
    }
  }, [isOpen]);

  return (
    <ParagraphWrapper isOpen={isOpen} contentHeight={height}>
      <Paragraph ref={contentRef}>{children}</Paragraph>
    </ParagraphWrapper>
  );
}

export default Terms;
